import React, { memo, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Box, Fade, Popper } from '@mui/material';
import { Coordinate } from 'ol/coordinate';
import { useMarkerOverlay } from '../../hooks/useMarkerOverlay';
import Marker, { MarkerProps } from '../Marker';
import { style } from './style';

export interface MarkerWithOverlayProps extends MarkerProps {
  renderOverlay: (coordinate: Coordinate) => React.ReactNode;
}

export const MarkerWithOverlay = memo(function MarkerOverlay({
  renderOverlay,
  ...markerProps
}: MarkerWithOverlayProps) {
  const uniqueID = useMemo(() => crypto.randomUUID(), []);

  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);

  const { isOpen, overlayCoord, containerRef } = useMarkerOverlay({
    markerID: uniqueID,
  });

  const virtualElement = {
    getBoundingClientRect: () => containerRef.current!.getBoundingClientRect(),
    nodeType: 1,
  };

  return (
    <>
      <Marker id={uniqueID} {...markerProps} isOverlayOpen={isOpen} />
      {isOpen &&
        containerRef.current &&
        createPortal(
          <Popper
            open
            anchorEl={virtualElement}
            disablePortal
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
            ]}
            sx={style.popper}
            transition
          >
            {({ TransitionProps }) => (
              <>
                <Box component='span' sx={style.arrow} ref={setArrowRef} />
                <Fade {...TransitionProps} timeout={350}>
                  <div>{renderOverlay(overlayCoord ?? [0, 0])}</div>
                </Fade>
              </>
            )}
          </Popper>,
          containerRef.current,
        )}
    </>
  );
});
