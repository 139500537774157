import React, { useEffect, useRef, useMemo, ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';
import { Icon, Style, Text, Fill, Stroke } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import { Vector as VectorLayer } from 'ol/layer';
import { Feature } from 'ol';
import { SFMap } from '../services';
import { noop } from '../../global';
import { MarkerProps } from '../components/Marker';

interface UseMarkerCreationParams extends MarkerProps {
  uniqueID: string;
  isOverlayOpen?: boolean;
}

export function useMarkerCreation({
  icon,
  position,
  rotation,
  draggable = false,
  label,
  zIndex = 0,
  anchor = [0.5, 0.5],
  onChange,
  uniqueID,
  isOverlayOpen = false,
}: UseMarkerCreationParams) {
  const [lat, lng] = position;

  const markerLayerRef = useRef<VectorLayer<
    VectorSource<Feature<Point>>
  > | null>(null);
  const labelLayerRef = useRef<VectorLayer<
    VectorSource<Feature<Point>>
  > | null>(null);
  const cleanupRef = useRef<() => void>(noop);

  const iconSrc = useMemo(() => {
    if (!icon) return null;

    if (typeof icon === 'function') {
      const resultingIcon = icon(isOverlayOpen);
      if (typeof resultingIcon !== 'string') {
        const markup = ReactDOMServer.renderToStaticMarkup(
          resultingIcon as ReactElement,
        );
        return `data:image/svg+xml;utf8,${encodeURIComponent(markup)}`;
      }
      return resultingIcon;
    }
    if (React.isValidElement(icon)) {
      const markup = ReactDOMServer.renderToStaticMarkup(icon);
      return `data:image/svg+xml;utf8,${encodeURIComponent(markup)}`;
    }
    return icon as string;
  }, [icon, isOverlayOpen]);

  useEffect(() => {
    if (!iconSrc && !label) {
      return noop;
    }

    const { cleanup, markerLayer, labelLayer } = SFMap.createMarker({
      position: fromLonLat([lng, lat]),
      id: uniqueID,
      draggable,
      zIndex,
      onCoordinateChange: onChange,
      shouldShowLabel: !!label,
    });

    markerLayerRef.current = markerLayer;
    cleanupRef.current = cleanup;

    if (label && labelLayer) {
      labelLayer.setDeclutter(true);
      labelLayer.setStyle(
        new Style({
          text: new Text({
            text: label,
            offsetY: 20,
            fill: new Fill({ color: '#fff' }),
            stroke: new Stroke({ color: '#000', width: 3 }),
            overflow: true,
          }),
        }),
      );
    }

    return () => {
      cleanupRef.current();
    };
  }, [uniqueID, lng, lat, draggable, zIndex, onChange, iconSrc, label]);

  useEffect(() => {
    if (!markerLayerRef.current) return;

    const features = markerLayerRef.current.getSource()?.getFeatures() || [];
    features.forEach((feature) => {
      if (feature.getGeometry() instanceof Point) {
        const geometry = feature.getGeometry() as Point | null;
        if (geometry) {
          geometry.setCoordinates(fromLonLat([lng, lat]));
        }
      }
    });
  }, [lat, lng]);

  useEffect(() => {
    if (!markerLayerRef.current) return;
    if (!iconSrc) {
      markerLayerRef.current.setStyle(null);
      return;
    }

    markerLayerRef.current.setStyle((feature, resolution) => {
      const style = new Style({
        image: new Icon({
          src: iconSrc,
          anchor,
          rotation,
        }),
      });

      style.getImage()?.setScale(1 / resolution ** (1 / 6));
      return style;
    });
  }, [iconSrc, rotation, anchor]);

  useEffect(() => {
    if (!markerLayerRef.current) return;
    markerLayerRef.current.setZIndex(zIndex);
  }, [zIndex]);

  return {
    markerLayerRef,
    labelLayerRef,
  };
}
