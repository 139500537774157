import { Box, Popover } from '@mui/material';
import { useState, MouseEvent, ReactNode } from 'react';
import { style } from './style';

type Props = {
  Icon: ReactNode;
  children?: ReactNode | undefined | ((open: boolean) => ReactNode);
};

function DriverControl({ children, Icon }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handleClose}
        component='span'
        sx={style.iconWrapper}
      >
        {Icon}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
        }}
      >
        {typeof children === 'function' ? children(open) : children}
      </Popover>
    </>
  );
}

DriverControl.displayName = 'DriverControl';

export default DriverControl;
