import { Control } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';

export enum MapType {
  GOOGLE_TERRAIN = 'googleTerrain',
  GOOGLE_SATELLITE = 'googleSatellite',
  GOOGLE_TRAFFIC = 'googleTraffic',
  YANDEX_TERRAIN = 'yandexTerrain',
  OSM_MAP = 'osmMap',
}

export enum MapControlType {
  FULL_SCREEN_CONTROL = 'MAP_CONTROL_FULL_SCREEN',
  GEOFENCE_CONTROL = 'MAP_CONTROL_GEOFENCE',
  MEASURE_CONTROL = 'MAP_CONTROL_MEASURE',
  LOCATIONS_CONTROL = 'MAP_CONTROL_LOCATIONS',
  MAP_CONTROL = 'MAP_CONTROL',
  ZOOM_CONTROL = 'MAP_CONTROL_ZOOM',
  CUSTOM_SHOW_SIDEBAR = 'CUSTOM_SHOW_SIDEBAR',
  CUSTOM_DATE_RANGE_PICKER = 'CUSTOM_DATE_RANGE_PICKER',
  MONITORING_HISTORY_EVENTS = 'MONITORING_HISTORY_EVENTS',
  MONITORING_LIVE_CONTROL = 'MONITORING_LIVE_CONTROL',
}

export type SFControlType = {
  width: number;
  control: Control;
  controlType: MapControlType;
  customCSS: Record<string, string>;
  position: 'left' | 'right';
  hidden: boolean;
};

export type MarkerConfig = {
  position: Coordinate;
  id?: number | string;
  draggable?: boolean;
  onCoordinateChange?: (coordinate: Coordinate) => void;
  zIndex?: number;
  shouldShowLabel?: boolean;
};

export type SFMarkerInstance = {
  cleanup: () => void;
  markerLayer: Vector<VectorSource<Feature<Point>>> | null;
  labelLayer: Vector<VectorSource<Feature<Point>>> | null;
  setPosition: (coordinate: Coordinate) => void;
};
