import { Box } from '@mui/material';
import noSignalAntenna from '../../../../assets/internet-antenna-status/NotSignalAntenna.svg';
import badSignalAntenna from '../../../../assets/internet-antenna-status/BadSignalAntenna.svg';
import lowSignalAntenna from '../../../../assets/internet-antenna-status/LowSignalAntenna.svg';
import normalSignalAntenna from '../../../../assets/internet-antenna-status/NormalSignalAntenna.svg';
import goodSignalAntenna from '../../../../assets/internet-antenna-status/GoodSignalAntenna.svg';
import { AntennaSignalQuality } from '../../utils/constants';

interface AntennaSignalIconProps {
  quality: AntennaSignalQuality;
  width?: number;
  height?: number;
}

function AntennaSignalIcon({
  quality,
  width = 24,
  height = 24,
}: AntennaSignalIconProps) {
  switch (quality) {
    case AntennaSignalQuality.NO_SIGNAL:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={noSignalAntenna}
          alt='No Signal'
        />
      );
    case AntennaSignalQuality.VERY_WEAK:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={badSignalAntenna}
          alt='Very Weak Signal'
        />
      );
    case AntennaSignalQuality.WEAK:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={lowSignalAntenna}
          alt='Weak Signal'
        />
      );
    case AntennaSignalQuality.STRONG:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={normalSignalAntenna}
          alt='Strong Signal'
        />
      );
    case AntennaSignalQuality.VERY_STRONG:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={goodSignalAntenna}
          alt='Very Strong Signal'
        />
      );
    default:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={noSignalAntenna}
          alt='No Signal'
        />
      );
  }
}

AntennaSignalIcon.displayName = 'AntennaSignalIcon';

export default AntennaSignalIcon;
