import { useTranslation } from 'react-i18next';
import SidebarContentPopover from '../SidebarContentPopover';
import DriverControl from '../DriverControl';
import { MonitoringMovementStatus, PopOverItem } from '../../types';
import { getLastDataReceivedOption } from '../../utils';
import { MovementStatusIcon } from '../SidebarIcons';

type Props = {
  status?: MonitoringMovementStatus;
  inMovement: boolean;
  vehicleSpeed?: number | null;
  lastData?: number | null;
};
export default function MovementsInfo({
  status,
  inMovement,
  vehicleSpeed,
  lastData,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  if (status == undefined) {
    status = MonitoringMovementStatus.NO_DATA;
  }
  const movementsIconPopOverItems: PopOverItem[] = [
    {
      label: t('movementStatus'),
      value: t(`movementStatusType.${status}`),
      getIsActive: () => inMovement,
    },
    {
      label: t('currentSpeed'),
      value: vehicleSpeed ? `${vehicleSpeed} km/h` : '0 km/h',
    },
    getLastDataReceivedOption(lastData),
  ];
  return (
    <DriverControl Icon={<MovementStatusIcon status={status} />}>
      <SidebarContentPopover items={movementsIconPopOverItems} />
    </DriverControl>
  );
}
