import { useTranslation } from 'react-i18next';
import DriverControl from '../DriverControl';
import SidebarContentPopover from '../SidebarContentPopover';
import { PopOverItem } from '../../types';
import { getGsmAntennaTitle, getLastDataReceivedOption } from '../../utils';
import { AntennaSignalIcon } from '../SidebarIcons';
import { AntennaSignalQuality } from '../../utils/constants';

type Props = {
  satellites?: number | null;
  lastData?: null | number;
  gsmSignalQuality: AntennaSignalQuality;
};
export default function GpsAntenna({
  satellites,
  lastData,
  gsmSignalQuality,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const gpsAntennaOptions = getGsmAntennaTitle(satellites);
  const popOverItem: PopOverItem[] = [
    {
      label: t('gpsSignal'),
      value: `${t(`connectivityState.${gpsAntennaOptions.signalQuality}`)} ${satellites ? `(${gpsAntennaOptions.ratio}/33)` : ''}`,
    },
    getLastDataReceivedOption(lastData),
  ];
  return (
    <DriverControl Icon={<AntennaSignalIcon quality={gsmSignalQuality} />}>
      <SidebarContentPopover items={popOverItem} />
    </DriverControl>
  );
}
