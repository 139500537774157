import { ReactNode, useMemo } from 'react';
import { Coordinate } from 'ol/coordinate';
import { useMarkerCreation } from '../../hooks/useMarkerCreation';

export interface MarkerProps {
  icon?: ((isOverlayOpen: boolean) => ReactNode) | ReactNode;
  position: Coordinate;
  rotation?: number;
  draggable?: boolean;
  label?: string;
  zIndex?: number;
  anchor?: [number, number];
  onChange?: (coordinate: Coordinate) => void;
  id?: string;
  isOverlayOpen?: boolean;
}

function Marker({
  icon,
  position,
  rotation,
  draggable,
  label,
  zIndex,
  anchor,
  onChange,
  id,
  isOverlayOpen = false,
}: MarkerProps) {
  const uniqueID = useMemo(() => id || crypto.randomUUID(), [id]);

  useMarkerCreation({
    icon,
    position,
    rotation,
    draggable,
    label,
    zIndex,
    anchor,
    onChange,
    uniqueID,
    isOverlayOpen,
  });

  return null;
}

export default Marker;
