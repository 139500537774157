// useMarkerOverlay.ts
import { useEffect, useRef, useState, useCallback } from 'react';
import Overlay from 'ol/Overlay';
import { Coordinate } from 'ol/coordinate';
import { MapBrowserEvent } from 'ol';
import { SFMap } from '../services';

interface UseMarkerOverlayParams {
  markerID: string;
}

export function useMarkerOverlay({ markerID }: UseMarkerOverlayParams) {
  const [isOpen, setIsOpen] = useState(false);
  const [overlayCoord, setOverlayCoord] = useState<Coordinate | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<Overlay | null>(null);

  useEffect(() => {
    containerRef.current = document.createElement('div');

    containerRef.current.style.width = '20px';
    containerRef.current.style.height = '40px';

    overlayRef.current = new Overlay({
      element: containerRef.current,
      positioning: 'bottom-center',
      offset: [0, 20],
      autoPan: true,
    });

    SFMap.getMap().addOverlay(overlayRef.current);

    return () => {
      if (overlayRef.current) {
        SFMap.getMap().removeOverlay(overlayRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!overlayRef.current) return;
    if (isOpen && overlayCoord) {
      overlayRef.current.setPosition(overlayCoord);
    } else {
      overlayRef.current.setPosition(undefined);
    }
  }, [isOpen, overlayCoord]);

  const handleMapClick = useCallback(
    (event: MapBrowserEvent<UIEvent>) => {
      const map = SFMap.getMap();
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat) => feat, {
        layerFilter: (layer) => layer.get('type') === 'markerLayer',
      });
      if (!feature) {
        setIsOpen(false);
        return;
      }

      const featureID = feature.get('id');
      if (featureID === markerID) {
        setIsOpen((prev) => !prev);
        setOverlayCoord(event.coordinate);
      }
    },
    [markerID],
  );

  useEffect(() => {
    const map = SFMap.getMap();
    map.on('click', handleMapClick);

    return () => {
      map.un('click', handleMapClick);
    };
  }, [handleMapClick]);

  return {
    isOpen,
    overlayCoord,
    containerRef,
  };
}
