import { Box } from '@mui/material';
import { MonitoringMovementStatus } from '../../types';
import movementActiveIcon from '../../../../assets/movements-status-icons/MovementsActive.svg';
import movementIdlingIcon from '../../../../assets/movements-status-icons/MovementsIdling.svg';
import movementParkingIcon from '../../../../assets/movements-status-icons/MovementsParking.svg';
import movementStopIcon from '../../../../assets/movements-status-icons/MovementsStop.svg';

interface MovementStatusIconProps {
  status?: MonitoringMovementStatus;
  width?: number;
  height?: number;
}

function MovementStatusIcon({
  status,
  width = 24,
  height = 24,
}: MovementStatusIconProps) {
  switch (status) {
    case MonitoringMovementStatus.PARKING:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={movementParkingIcon}
          alt='Car Parking'
        />
      );
    case MonitoringMovementStatus.STOP:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={movementStopIcon}
          alt='Car Stop'
        />
      );
    case MonitoringMovementStatus.IDLING:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={movementIdlingIcon}
          alt='Car Idling'
        />
      );
    case MonitoringMovementStatus.MOVEMENT:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={movementActiveIcon}
          alt='Car is Moving'
        />
      );
    default:
      return (
        <Box
          width={width}
          height={height}
          component='img'
          src={movementParkingIcon}
          alt='Car Parking'
        />
      );
  }
}

MovementStatusIcon.displayName = 'MovementStatusIcon';

export default MovementStatusIcon;
