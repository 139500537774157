import { ReactNode } from 'react';
import { LogicalOperator, MatchingMethod } from '../../global';
import { DeviceStatus, VehicleType } from '../../vehicles/types';
import { MonitoringTripDTO } from '../services/monitoring.service';
import { MonitoringMode, TransportFuelType } from '../../shared/types';
import { AntennaSignalQuality } from '../utils/constants';

export type MonitoringTeam = {
  id: number;
  name: string;
  transportsCount: number;
};

export type MonitoringTransportDTO = MonitoringDriverDTO &
  MonitoringVehicleLocationDTO;
export enum MonitoringReportOption {
  'SPEED' = 'speed',
  'TEMPERATURE' = 'temperature',
  'RELIEF' = 'altitude',
  'GSM_SIGNAL' = 'gsmSignal',
  'GPS_SIGNAL' = 'satellites',
  'VOLTAGE' = 'batteryVoltage',
}

export interface MonitoringFlatTransportDTO
  extends MonitoringDriverDTO,
    MonitoringVehicleDeviceDTO {
  status: MonitoringMovementStatus;
  gsmSignalQuality: AntennaSignalQuality;
  satellitesQuality: AntennaSignalQuality;
}

export type MonitoringSlice = {
  map: {
    teams: Record<string, MonitoringTeamDTO>;
    transports: Record<string, Partial<MonitoringFlatTransportDTO>>;
  };
  trips: {
    all: Array<MonitoringTripDTO>;
    overallTrip: Omit<
      MonitoringTripDTO,
      'id' | 'deviceId' | 'transportId'
    > | null;
    tripState: MonitoringTripState;
    tripProgress: number;
    tripSpeed: number;
    loading: boolean;
  };
  trackInfo: {
    allSelected: boolean;
    teamId: number | null;
    transportId: number | null;
  };
  sidebarFilters: {
    searchResults: {
      loading: boolean;
      data: MonitoringDriverDTO[];
      total: number;
      searching: boolean;
    };
    teamIds: number[];
  };
  filter: {
    startTime: string;
    endTime: string;
    excludeWeekends: boolean;
  };
  general: MonitoringGeneralInfo;
  mode: MonitoringMode;
};

export const enum MonitoringSearchOption {
  LICENSE_PLATE = 'licensePlate',
  TRANSPORT_TYPE = 'vehicleType',
  DRIVER_NAME = 'name',
  TEAM_ID = 'team:id',
  TEAM_NAME = 'team:name',
}
export type MonitoringTransportSearchParams = {
  search?: string;
  team?: string[] | null;
};

export type MonitoringDriverDTO = {
  id: number;
  name: string | null;
  type: VehicleType | null;
  licensePlate: string | null;
  vin: string | null;
  imageId: string | null;
  courier?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  team?: {
    id: number;
    name: string;
  };
  device?: {
    id: number;
    status: DeviceStatus;
    data?: {
      gsmSignal?: number;
      satellites?: number;
    };
  };
};

export interface MonitoringVehicleDeviceDTO {
  altitude: number;
  bearing: number;
  latitude: number;
  longitude: number;
  speed: number;
  movement: number;
  createdAt: string;
  engineRpm: number | null;
  satellites: number;
  batteryVoltage: number;
  externalVoltage: number;
  gnssStatus: number;
  gsmSignal: number;
}

export type MonitoringVehicleLocationDTO = {
  id: number;
  name?: string;
  type: VehicleType;
  licensePlate: string | null;
  status: MonitoringMovementStatus;
  device?: {
    data: Array<MonitoringVehicleDeviceDTO>;
    id: number;
    status: DeviceStatus;
  };
};

export enum MonitoringMovementStatus {
  MOVEMENT = 'MOVEMENT',
  IDLING = 'IDLING',
  STOP = 'STOP',
  PARKING = 'PARKING',
  NO_DATA = 'NO_DATA',
  NA = 'NA',
}

export interface PopOverItem {
  label: string;
  value: ReactNode;
  getIsActive?: () => boolean;
}

export interface MonitoringTeamDTO {
  id: number;
  name: string;
  total: number;
  transportIds: number[];
}

export interface MonitoringVehicleDetailedDTO {
  courierId: number;
  courierName: string;
  transportName: string;
  transportId: number;
  vehicleType: VehicleType;
  licensePlate: string | null;
  currentKilometer: number;
  latitude: number;
  longitude: number;
  movement: number;
  speed: number;
  batteryVoltage: number;
  altitude: number;
  gsmSignal: number;
  gnssStatus: number;
  profileImageId: string | null;
  lastActive: string;
  transportStatus: MonitoringMovementStatus;
  deviceStatus: 0 | 1;
  externalVoltage: number;
  satellites: number;
}
export type SignalConnectionOptions = {
  signalQuality: string;
  ratio: number;
};

export interface MonitoringUpdateDTO {
  transportId: number;
  data: Array<{
    device: {
      id: number;
    };
    id: number;
    eventId: number;
    position: {
      lat: number;
      lng: number;
      alt: number;
    };
    bearing: number;
    satellites?: number;
    speed?: number;
    engineRpm: number | null;
    data: {
      totalOdometer: number | null;
      gsmSignal?: number;
      temperature: number | null;
      numberOfDtc: number | null;
      engineLoad: number | null;
      coolantTemperature: number | null;
      intakeMap: number | null;
      throttlePosition: number | null;
      fuelLevel: number | null;
      distanceSinceCodesClear: number | null;
      barometricPressure: number | null;
      ambientAirTemperature: null;
      externalVoltage: number | null;
      batteryVoltage: number | null;
      batteryCurrent: number | null;
      gnssStatus: number | null;
      dataMode: number | null;
      batteryLevel: number | null;
      tripOdometer: number | null;
      ignition: number | null;
      overSpeeding: number | null;
      greenDrivingDuration: number | null;
      vin: number | null;
    };
    greenDrivingValue: number | null;
    createdAt: string;
    status: MonitoringMovementStatus;
  }>;
}

export interface MonitoringRequestFilter {
  startDate: string;
  endDate?: string;
  transportId: number;
  limit?: number;
  source?: 'monitoring' | 'alerts';
  tripId?: string;
}
export { MatchingMethod, LogicalOperator };

export enum MonitoringTripState {
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  INITIAL = 'INITIAL',
}

export interface MonitoringGeneralInfo {
  avgConsumption: number | null;
  avgSpeed: number | null;
  distancePassed: number | null;
  drivingTime: number | null;
  fuelConsumption: number | null;
  humidity: string | null;
  idlingTime: number | null;
  parkingTime: number | null;
  temperature: string | null;
  fuelType: TransportFuelType;
  isLoading: boolean;
}

export type MonitoringDeviceHistoryTab = 'general' | 'notifications' | 'trips';
export type MonitoringDateFilter =
  | 'custom'
  | 'today'
  | 'yesterday'
  | 'lastWeek'
  | 'lastMonth';
