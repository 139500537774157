import { Coordinate } from 'ol/coordinate';
import { Icon, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';
import { SFMapType } from './map.service';
import { SFMarkerInstance } from '../types';

export interface RouteInitConfig {
  data: Array<{
    coordinate: Coordinate;
    bearing: number;
  }>;
  totalTime: number;
  markerIconUrl: string;
  progressSubscribers?: Array<(progress: number) => void>;
}

class SFRoute {
  private playing: boolean;

  private data: Array<{
    coordinate: Coordinate;
    bearing: number;
  }>;

  private currentIndex: number;

  private steps: number;

  private step: number;

  private totalRouteTimeInMilliseconds: number;

  private marker: null | SFMarkerInstance;

  private markerLayer: VectorLayer<VectorSource>;

  private mapInstance: SFMapType;

  private markerIconUrl: string;

  private progress: number;

  private progressSubscribers: Array<(progress: number) => void>;

  constructor(instance: SFMapType) {
    this.mapInstance = instance;
    this.data = [];
    this.currentIndex = 0;
    this.playing = false;
    this.steps = 100;
    this.step = 0;
    this.marker = null;
    this.totalRouteTimeInMilliseconds = 0;
    this.markerIconUrl = '';
    this.progress = 0;
    this.progressSubscribers = [];

    this.markerLayer = new VectorLayer({
      source: new VectorSource(),
    });

    this.mapInstance.addOverlayLayer(this.markerLayer);
  }

  private updateProgress() {
    this.progress = (this.currentIndex / this.data.length) * 100;
    this.progressSubscribers.forEach((fn) => fn(this.progress));
  }

  private static interpolateCoords(
    start: Coordinate,
    end: Coordinate,
    t: number,
  ): Coordinate {
    return [
      start[0] + (end[0] - start[0]) * t,
      start[1] + (end[1] - start[1]) * t,
    ];
  }

  private moveMarker(time: number) {
    if (
      !this.playing ||
      this.currentIndex >= this.data.length - 1 ||
      time === 0
    ) {
      return;
    }

    const start = this.data[this.currentIndex];
    const end = this.data[this.currentIndex + 1];
    const t = this.step / this.steps;
    const currentPosition = SFRoute.interpolateCoords(
      start.coordinate,
      end.coordinate,
      t,
    );

    this.marker?.setPosition(currentPosition);

    this.marker?.markerLayer?.setStyle(
      new Style({
        image: new Icon({
          src: this.markerIconUrl,
          rotation: (start.bearing * Math.PI) / 180,
        }),
      }),
    );

    this.step += 1;

    if (this.step >= this.steps) {
      this.step = 0;
      this.currentIndex += 1;
      this.updateProgress();
      if (this.currentIndex >= this.data.length - 1) {
        this.playing = false;
      }
    }

    const nextIterationTimeout = time / this.steps;

    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      this.moveMarker(time - nextIterationTimeout);
    }, nextIterationTimeout);
  }

  play() {
    this.playing = true;
    this.moveMarker(this.totalRouteTimeInMilliseconds / this.data.length);
  }

  stop() {
    this.playing = false;
  }

  init({
    data,
    totalTime,
    markerIconUrl,
    progressSubscribers = [],
  }: RouteInitConfig) {
    this.playing = false;
    this.data = data;
    this.totalRouteTimeInMilliseconds = totalTime;
    this.markerIconUrl = markerIconUrl;
    this.currentIndex = 0;
    this.step = 0;
    this.progress = 0;
    this.progressSubscribers = progressSubscribers;

    this.marker = this.mapInstance.createMarker({
      position: fromLonLat(this.data[0].coordinate),
    });

    this.marker.setPosition(data[0].coordinate);

    this.marker.markerLayer?.setStyle(
      new Style({
        image: new Icon({
          src: markerIconUrl,
        }),
      }),
    );
  }
}

export default SFRoute;
