import { useEffect, useMemo } from 'react';
import socket from '../utils/socket';
import { MonitoringFlatTransportDTO, MonitoringUpdateDTO } from '../types';
import { monitoringUpdateVehicle } from '../slices/monitoring-slice';
import { useAppDispatch } from '../../layout/store';
import { noop } from '../../global';

const useRealTimeVehicleMonitoring = (
  vehicles: Array<Partial<MonitoringFlatTransportDTO>>,
) => {
  const dispatch = useAppDispatch();

  const vehicleIDs = useMemo(() => {
    return vehicles
      .filter((v) => v.device?.id)
      .map((v) => v.id)
      .filter(Boolean);
  }, [vehicles]);

  useEffect(() => {
    socket.connect();

    const handleSocketError = (error: unknown) => {
      console.error('Socket error:', error);
    };
    socket.on('error', handleSocketError);

    const handleUpdate = (update: MonitoringUpdateDTO) => {
      dispatch(monitoringUpdateVehicle(update));
    };
    socket.on('update', handleUpdate);

    return () => {
      socket.off('error', handleSocketError);
      socket.off('update', handleUpdate);
      socket.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!vehicleIDs.length) return noop;

    const joinRooms = () => {
      socket.emit('joinRoom', { transports: vehicleIDs });
    };

    socket.on('connect', joinRooms);

    if (socket.connected) {
      joinRooms();
    }

    return () => {
      socket.off('connect', joinRooms);
    };
  }, [vehicleIDs]);

  useEffect(() => {
    const handleOffline = () => {
      console.log('Network offline. Socket will likely disconnect...');
    };

    const handleOnline = () => {
      console.log('Network online. Attempting to (re)connect socket...');
      if (!socket.connected) {
        socket.connect();
      }
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);
};

export default useRealTimeVehicleMonitoring;
