import { MonitoringMovementStatus } from '../../types';
import GreenBicycle from '../../../../assets/vehicle/green/bicycle-green.svg?component';
import RedBicycle from '../../../../assets/vehicle/red/bicycle-red.svg?component';
import YellowBicycle from '../../../../assets/vehicle/yellow/bicycle-yellow.svg?component';
import GrayBicycle from '../../../../assets/vehicle/gray/bicycle-gray.svg?component';
import GreenMoto from '../../../../assets/vehicle/green/moto-green.svg?component';
import RedMoto from '../../../../assets/vehicle/red/moto-red.svg?component';
import YellowMoto from '../../../../assets/vehicle/yellow/moto-yellow.svg?component';
import GrayMoto from '../../../../assets/vehicle/gray/moto-gray.svg?component';
import GraySedan from '../../../../assets/vehicle/gray/sedan-gray.svg?component';
import GreenSedan from '../../../../assets/vehicle/green/sedan-green.svg?component';
import RedSedaN from '../../../../assets/vehicle/red/sedan-red.svg?component';
import YellowSedan from '../../../../assets/vehicle/yellow/sedan-yellow.svg?component';
import GreenTruck from '../../../../assets/vehicle/green/truck-green.svg?component';
import RedTruck from '../../../../assets/vehicle/red/truck-red.svg?component';
import YellowTruck from '../../../../assets/vehicle/yellow/truck-yellow.svg?component';
import GrayTruck from '../../../../assets/vehicle/gray/truck-gray.svg?component';
import GreenWalk from '../../../../assets/vehicle/green/walk-green.svg?component';
import RedWalk from '../../../../assets/vehicle/red/walk-red.svg?component';
import YellowWalk from '../../../../assets/vehicle/yellow/walk-yellow.svg?component';
import GrayWalk from '../../../../assets/vehicle/gray/walk-gray.svg?component';
import { VehicleType } from '../../../shared/types';

function BicycleIcon({ status }: UnitIconProps) {
  switch (status) {
    case MonitoringMovementStatus.STOP:
      return <RedBicycle />;
    case MonitoringMovementStatus.MOVEMENT:
      return <GreenBicycle />;
    case MonitoringMovementStatus.IDLING:
      return <YellowBicycle />;
    case MonitoringMovementStatus.PARKING:
    case MonitoringMovementStatus.NO_DATA:
      return <GrayBicycle />;

    default:
      return null;
  }
}

function MotoIcon({ status }: UnitIconProps) {
  switch (status) {
    case MonitoringMovementStatus.STOP:
      return <RedMoto />;
    case MonitoringMovementStatus.MOVEMENT:
      return <GreenMoto />;
    case MonitoringMovementStatus.IDLING:
      return <YellowMoto />;
    case MonitoringMovementStatus.NO_DATA:
    case MonitoringMovementStatus.PARKING:
      return <GrayMoto />;
    default:
      return null;
  }
}

interface UnitIconProps {
  status?: MonitoringMovementStatus;
}

function SedanIcon({ status }: UnitIconProps) {
  switch (status) {
    case MonitoringMovementStatus.STOP:
      return <RedSedaN />;
    case MonitoringMovementStatus.MOVEMENT:
      return <GreenSedan />;
    case MonitoringMovementStatus.IDLING:
      return <YellowSedan />;
    case MonitoringMovementStatus.PARKING:
    case MonitoringMovementStatus.NO_DATA:
      return <GraySedan />;
    default:
      return null;
  }
}

function TruckIcon({ status }: UnitIconProps) {
  switch (status) {
    case MonitoringMovementStatus.STOP:
      return <RedTruck />;
    case MonitoringMovementStatus.MOVEMENT:
      return <GreenTruck />;
    case MonitoringMovementStatus.IDLING:
      return <YellowTruck />;
    case MonitoringMovementStatus.PARKING:
    case MonitoringMovementStatus.NO_DATA:
      return <GrayTruck />;
    default:
      return null;
  }
}

function WalkIcon({ status }: UnitIconProps) {
  switch (status) {
    case MonitoringMovementStatus.STOP:
      return <RedWalk />;
    case MonitoringMovementStatus.MOVEMENT:
      return <GreenWalk />;
    case MonitoringMovementStatus.IDLING:
      return <YellowWalk />;
    case MonitoringMovementStatus.PARKING:
    case MonitoringMovementStatus.NO_DATA:
      return <GrayWalk />;
    default:
      return null;
  }
}

interface TransportIconProps {
  vehicleType?: VehicleType | null;
  status?: MonitoringMovementStatus;
}

function TransportIcon({ vehicleType, status }: TransportIconProps) {
  switch (vehicleType) {
    case VehicleType.CAR:
      return <SedanIcon status={status} />;
    case VehicleType.BIKE:
      return <BicycleIcon status={status} />;
    case VehicleType.MOTO:
      return <MotoIcon status={status} />;
    case VehicleType.TRUCK:
      return <TruckIcon status={status} />;
    case VehicleType.WALK:
      return <WalkIcon status={status} />;
    default:
      return null;
  }
}

export default TransportIcon;
