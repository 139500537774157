import { FetchHttpClient, IHttpClient } from './fetch-http-client';
import {
  IMiddleware,
  IMiddlewareManager,
  MiddlewareContext,
  MiddlewareManager,
  MiddlewarePhase,
} from './middleware-manager';
import { CacheMiddleware } from './middlewares/cache-middleware';
import { AuthMiddleware } from './middlewares/auth-middleware';
import { ContentTypeMiddleware } from './middlewares/content-type-middleware';
import { ErrorHandlingMiddleware } from './middlewares/error-handling-middleware';

export interface ApiRequest {
  method: string;
  url: string;
  headers: Headers;
  retrying?: boolean;
  body?: any;
  options?: RequestOptions;
  signal?: AbortSignal;
}

export type ApiResponse<T = any> = {
  error?: any;
  ok?: boolean;
  status?: number;
  headers?: Headers;
  data: T;
};

export interface RequestOptions {
  notifyErrors?: boolean;
  retrying?: number;
  timeout?: number;
  cache?: boolean;
  signal?: AbortSignal;
}

export interface IApiService {
  use(middleware: IMiddleware, phase?: MiddlewarePhase): void;
  get<T>(
    endpoint: string,
    params?: Record<string, string | null>,
    options?: RequestOptions,
  ): Promise<ApiResponse<T>>;
  post<T>(
    endpoint: string,
    data: any,
    options?: RequestOptions,
  ): Promise<ApiResponse<T>>;
  put<T>(
    endpoint: string,
    data: any,
    options?: RequestOptions,
  ): Promise<ApiResponse<T>>;
  patch<T>(
    endpoint: string,
    data: any,
    options?: RequestOptions,
  ): Promise<ApiResponse<T>>;
  delete(endpoint: string, options?: RequestOptions): Promise<void>;
}

class ApiService implements IApiService {
  private static instance: ApiService;

  private readonly middlewareManager: IMiddlewareManager;

  private readonly httpClient: IHttpClient;

  static readonly BASE_URL = 'https://api-new.superfleet.ai';

  readonly BASE_URL = 'https://api-new.superfleet.ai';

  private constructor(
    middlewareManager: IMiddlewareManager,
    httpClient: IHttpClient,
  ) {
    this.middlewareManager = middlewareManager;
    this.httpClient = httpClient;
    this.setupDefaultMiddlewares();
  }

  getToken() {
    return `Bearer ${localStorage.getItem('access_token')}`;
  }

  static getInstance(): ApiService {
    if (!ApiService.instance) {
      const middlewareManager = new MiddlewareManager();
      const httpClient = new FetchHttpClient(ApiService.BASE_URL);
      ApiService.instance = new ApiService(middlewareManager, httpClient);
    }
    return ApiService.instance;
  }

  private setupDefaultMiddlewares(): void {
    this.middlewareManager.use(new CacheMiddleware(60 * 1000), 'request');
    this.middlewareManager.use(new AuthMiddleware(), 'request');
    this.middlewareManager.use(new ContentTypeMiddleware(), 'request');

    this.middlewareManager.use(new ErrorHandlingMiddleware(), 'response');
  }

  use(middleware: IMiddleware, phase: MiddlewarePhase = 'request'): void {
    this.middlewareManager.use(middleware, phase);
  }

  private async executeRequest<T>(
    context: MiddlewareContext,
  ): Promise<ApiResponse<T>> {
    try {
      await this.middlewareManager.executeRequestMiddlewares(context);

      if (!context.response) {
        context.response = await this.httpClient.request<T>(context.request);
      }

      await this.middlewareManager.executeResponseMiddlewares(context);

      if (context.request.retrying && !context.response) {
        context.request.retrying = false;
        return this.executeRequest(context);
      }

      return context.response as ApiResponse<T>;
    } catch (error) {
      context.response = {
        ok: false,
        status: 500,
        error,
      } as ApiResponse<T>;

      return context.response;
    }
  }

  async get<T>(
    endpoint: string,
    params?: Record<string, string | null>,
    options: RequestOptions = { cache: true, signal: undefined },
  ): Promise<ApiResponse<T>> {
    const { signal, ...restOptions } = options;
    const searchParams = new URLSearchParams(
      params as Record<string, string>,
    ).toString();

    const context: MiddlewareContext = {
      request: {
        url: `${endpoint}?${searchParams}`,
        method: 'GET',
        retrying: false,
        headers: new Headers(),
        signal,
        options: {
          ...restOptions,
          retrying: 0,
        },
      },
    };

    return (await this.executeRequest(context)) as ApiResponse<T>;
  }

  async post<T>(
    endpoint: string,
    data: any,
    options: RequestOptions = {},
  ): Promise<ApiResponse<T>> {
    const { signal, ...restOptions } = options;
    const body = data instanceof FormData ? data : JSON.stringify(data);
    const context: MiddlewareContext = {
      request: {
        url: endpoint,
        method: 'POST',
        headers: new Headers(),
        retrying: false,
        body,
        signal,
        options: restOptions,
      },
    };

    return this.executeRequest<T>(context);
  }

  async put<T>(
    endpoint: string,
    data: any,
    options?: RequestOptions,
  ): Promise<ApiResponse<T>> {
    const context: MiddlewareContext = {
      request: {
        url: endpoint,
        method: 'PUT',
        retrying: false,
        headers: new Headers(),
        body: JSON.stringify(data),
        options,
      },
    };

    return this.executeRequest(context);
  }

  async patch<T>(
    endpoint: string,
    data: any,
    options?: RequestOptions,
  ): Promise<ApiResponse<T>> {
    const context: MiddlewareContext = {
      request: {
        url: endpoint,
        method: 'PATCH',
        retrying: false,
        headers: new Headers(),
        body: JSON.stringify(data),
        options,
      },
    };

    return this.executeRequest<T>(context);
  }

  async delete(endpoint: string, options?: RequestOptions): Promise<void> {
    const context: MiddlewareContext = {
      request: {
        url: endpoint,
        method: 'DELETE',
        retrying: false,
        headers: new Headers(),
        options,
      },
    };

    await this.executeRequest(context);
  }
}

export default ApiService.getInstance();
