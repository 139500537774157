import { io } from 'socket.io-client';
import ApiService from '../../global/services/api.service';

const socket = io(ApiService.BASE_URL, {
  extraHeaders: {
    Authorization: ApiService.getToken(),
  },
  autoConnect: false,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});

export default socket;
