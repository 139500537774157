import {
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { style } from './style';
import { ROUTES } from '../../../global';
import {
  MonitoringFlatTransportDTO,
  MonitoringMovementStatus,
} from '../../types';
import AvatarImage from '../../../shared/components/AvatarImage';
import StyledBadge from '../../../shared/components/StyledBadge';
import { toggleSearchParam } from '../../../shared/utils';
import TransportMetricsDisplay from './TransportMetricsDisplay';
import { useAppSelector } from '../../../layout/store';
import { getMonitoringModeSelector } from '../../selectors';
import { MonitoringMode } from '../../../shared/types';

type Props = Partial<MonitoringFlatTransportDTO> & {
  tracked: boolean;
  onClose?: () => void;
};

function MonitoringSingleTransport({
  courier,
  name,
  licensePlate,
  speed,
  gsmSignal,
  tracked,
  id,
  device,
  latitude,
  longitude,
  onClose,
  imageId,
  status,
  satellites,
  gsmSignalQuality,
  createdAt,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const [, setSearchParams] = useSearchParams();

  const inMovement = status === MonitoringMovementStatus.MOVEMENT;

  const [searchParams] = useSearchParams();

  const mode = useAppSelector(getMonitoringModeSelector);

  const isInHistory = mode === MonitoringMode.HISTORY;

  const selectedTransportID = searchParams.get(
    ROUTES.monitoring.searchParams.transportID,
  );

  const selectTransport = () => {
    onClose?.();
    setSearchParams((params) => {
      params = toggleSearchParam(
        params,
        ROUTES.monitoring.searchParams.transportID,
        id,
      );
      params.delete(ROUTES.monitoring.searchParams.tripID);
      return params;
    });
  };

  const transportInHistoryMode = selectedTransportID === String(id);
  return (
    <>
      <ListItem
        sx={[
          style.listItem,
          transportInHistoryMode && style.listItemSelected,
          !device?.id && style.disabled,
        ]}
        onClick={selectTransport}
      >
        <ListItemAvatar>
          {inMovement ? (
            <StyledBadge
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <AvatarImage
                imageId={imageId as null | string}
                altName='Transport Image'
              />
            </StyledBadge>
          ) : (
            <AvatarImage
              imageId={imageId as null | string}
              altName='Transport Image'
              size='XM'
            />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box sx={style.listItemSecondaryText} component='span'>
              <Typography sx={style.licensePlate} component='span'>
                {licensePlate || 'N/A'}
              </Typography>
              {name && (
                <>
                  <Box sx={style.dot} component='span' />
                  <Typography sx={style.transportName}>{name}</Typography>
                </>
              )}
            </Box>
          }
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{
            noWrap: true,
            fontWeight: 400,
            color: '#808080',
          }}
          secondary={
            courier
              ? `${courier.firstName || ''} ${courier.lastName || ''}`
              : t('notAssigned')
          }
          sx={style.listItemText}
        />
        <TransportMetricsDisplay
          deviceId={device?.id}
          transportId={id}
          tracked={tracked}
          inMovement={inMovement}
          vehicleSpeed={speed}
          gsmSignal={gsmSignal}
          latitude={latitude}
          longitude={longitude}
          status={status}
          satellites={satellites}
          lastData={createdAt ? new Date(createdAt).getTime() : null}
          isFollowDisabled={isInHistory}
          gsmSignalQuality={gsmSignalQuality}
        />
      </ListItem>
      <Divider component='li' />
    </>
  );
}

MonitoringSingleTransport.displayName = 'MonitoringSingleDriver';

export default MonitoringSingleTransport;
