import { Coordinate } from 'ol/coordinate';
import React, { useMemo } from 'react';
import { MonitoringFlatTransportDTO } from '../../types';
import MonitoringVehicleRealTimeInfo from './MonitoringVehicleRealTimeInfo';
import { MarkerWithOverlay } from '../../../map/components/MarkerWithOverlay';
import TransportIcon from '../MonitoringVehicleIcons';

interface MonitoringVehicleMarkerProps {
  vehicle: Partial<MonitoringFlatTransportDTO>;
}

function MonitoringVehicleMarker({ vehicle }: MonitoringVehicleMarkerProps) {
  const position = useMemo(
    () =>
      [vehicle.latitude as number, vehicle.longitude as number] as Coordinate,
    [vehicle.latitude, vehicle.longitude],
  );

  return (
    <MarkerWithOverlay
      position={position}
      icon={
        <TransportIcon vehicleType={vehicle.type} status={vehicle.status} />
      }
      key={vehicle.id || vehicle.id}
      rotation={vehicle.bearing}
      label={vehicle.name || ''}
      renderOverlay={() => (
        <MonitoringVehicleRealTimeInfo id={vehicle.id as number} />
      )}
    />
  );
}

MonitoringVehicleMarker.displayName = 'MonitoringVehicleMarker';

export default MonitoringVehicleMarker;
