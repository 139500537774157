import { AntennaSignalQuality } from './constants';

const getGSMSignalQuality = (gsmSignal?: number) => {
  if (!gsmSignal) {
    return AntennaSignalQuality.NO_SIGNAL;
  }
  if (gsmSignal === 1) {
    return AntennaSignalQuality.VERY_WEAK;
  }
  if (gsmSignal === 2 || gsmSignal === 3) {
    return AntennaSignalQuality.WEAK;
  }
  if (gsmSignal === 4) {
    return AntennaSignalQuality.STRONG;
  }
  return AntennaSignalQuality.VERY_STRONG;
};

export { getGSMSignalQuality };
