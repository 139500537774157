import { useTranslation } from 'react-i18next';
import { PopOverItem } from '../../types';
import SidebarContentPopover from '../SidebarContentPopover';
import DriverControl from '../DriverControl';
import {
  getInternetAntennaTitle,
  getLastDataReceivedOption,
} from '../../utils';
import { AntennaSignalIcon } from '../SidebarIcons';
import { AntennaSignalQuality } from '../../utils/constants';

type Props = {
  gsmSignal?: null | number;
  lastData?: number | null;
  gsmSignalQuality: AntennaSignalQuality;
};
export default function InternetAntenna({
  gsmSignal,
  gsmSignalQuality,
  lastData,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const internetAntennaOptions = getInternetAntennaTitle(gsmSignal);
  const popOverItem: PopOverItem[] = [
    {
      label: t('internetAntenna'),
      value: `${t(`connectivityState.${internetAntennaOptions.signalQuality}`)} ${gsmSignal ? `(${internetAntennaOptions.ratio - 1}/4)` : ''}`,
    },
    getLastDataReceivedOption(lastData),
  ];
  return (
    <DriverControl Icon={<AntennaSignalIcon quality={gsmSignalQuality} />}>
      <SidebarContentPopover items={popOverItem} />
    </DriverControl>
  );
}
