import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { startOfToday } from 'date-fns';
import { ROUTES } from '../../global';
import { MonitoringDateFilter } from '../types';

const useMonitoringDateFilter = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });

  const dateFilterLabelByParam: Record<MonitoringDateFilter, string> = useMemo(
    () => ({
      custom: t('dateRangePickerOptions.custom'),
      today: t('dateRangePickerOptions.today'),
      yesterday: t('dateRangePickerOptions.yesterday'),
      lastWeek: t('dateRangePickerOptions.lastWeek'),
      lastMonth: t('dateRangePickerOptions.lastMonth'),
    }),
    [t],
  );

  const endDateInSearchParams = searchParams.get(
    ROUTES.monitoring.searchParams.endDate,
  );

  const startDateInSearchParams = +(
    searchParams.get(ROUTES.monitoring.searchParams.startDate) ||
    startOfToday().getTime()
  );

  const dateFilterInSearchParams = (searchParams.get('dateFilter') ||
    'today') as MonitoringDateFilter;

  return useMemo(() => {
    return {
      startDate: new Date(startDateInSearchParams),
      endDate: endDateInSearchParams
        ? new Date(+endDateInSearchParams)
        : new Date(),
      dateFilterValue: dateFilterInSearchParams,
      dateFilterLabel: dateFilterInSearchParams
        ? dateFilterLabelByParam[
            dateFilterInSearchParams as MonitoringDateFilter
          ]
        : '',
    };
  }, [
    endDateInSearchParams,
    startDateInSearchParams,
    dateFilterInSearchParams,
    dateFilterLabelByParam,
  ]);
};

export default useMonitoringDateFilter;
