import { Box, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { style } from './style';

export interface DetailType {
  id: number;
  Icon: ReactElement;
  label: string;
  renderValue: () => ReactNode;
}
type Props = {
  data: DetailType[];
};
function ListRenderer({ data }: Props) {
  return (
    <>
      {data.map((el) => {
        return (
          <Box key={el.id} sx={style.listItem}>
            {el.Icon}
            <Typography variant='body1' sx={style.listItemPrimaryText}>
              {el.label}
            </Typography>

            {el.renderValue()}
          </Box>
        );
      })}
    </>
  );
}
export default ListRenderer;
