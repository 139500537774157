import { useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../global';

export const useSelectedTripId = () => {
  const [searchParams] = useSearchParams();

  const selectedTripId = searchParams.get(
    ROUTES.monitoring.searchParams.tripID,
  );

  return selectedTripId || 'all';
};
