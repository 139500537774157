import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getMonitoringTrips } from '../services/monitoring.service';
import useMonitoringDateFilter from './useMonitoringDateFilter';
import { useAppDispatch } from '../../layout/store';
import { noop } from '../../global';
import { useSelectedTripId } from './useSelectedTripId';

const useMonitoringTripsPreload = () => {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const transportID = searchParams.get('transportID');

  const tripId = useSelectedTripId();

  const { startDate, endDate } = useMonitoringDateFilter();

  useEffect(() => {
    if (transportID) {
      const dispatchResult = dispatch(
        getMonitoringTrips({
          startDate: startDate.toUTCString(),
          endDate: endDate.toUTCString(),
          transportId: +transportID,
          tripId: tripId === 'all' ? undefined : tripId,
        }),
      );

      return () => {
        dispatchResult.abort();
      };
    }

    return noop;
  }, [transportID, startDate, endDate, dispatch]);
};

export default useMonitoringTripsPreload;
