import {
  endOfMonth,
  endOfWeek,
  format,
  isToday,
  isWithinInterval,
  isYesterday,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns';
import { t } from 'i18next';
import { Box, Typography } from '@mui/material';
import { ContentCopy, NearMe } from '@mui/icons-material';
import * as controls from '../../map/services/controls';
import { MapControlType, SFControlType } from '../../map/types';
import {
  AntennaSignalQuality,
  MONITORING_SIDEBAR_COLLAPSE_STATE,
} from './constants';
import { MonitoringFlatTransportDTO, SignalConnectionOptions } from '../types';
import { DetailType } from '../../shared/components/ListRenderer';
import { style } from '../components/MonitoringDeviceDetails/style';
import { formatLatLng } from '../../global';
import ReliefIcon from '../../../assets/monitoring-device-details-icons/relief-icon.svg?component';
import LocationIcon from '../../../assets/monitoring-device-details-icons/location-Icon.svg?component';
import VoltageIcon from '../../../assets/monitoring-device-details-icons/voltageIcon.svg?component';
import DeviceStatusIcon from '../../../assets/monitoring-device-details-icons/device-status.svg?component';
import LastActiveIcon from '../../../assets/monitoring-device-details-icons/Last-active.svg?component';
import CurrentSpeedIcon from '../../../assets/monitoring-device-details-icons/current-speed.svg?component';
import {
  AntennaSignalIcon,
  MovementStatusIcon,
} from '../components/SidebarIcons';

export function getMonitoringControls(
  hideSidebar: boolean,
  inHistoryMode: boolean,
) {
  const controlList: SFControlType[] = [
    {
      control: new controls.FullScreenControl(),
      width: 36,
      controlType: MapControlType.FULL_SCREEN_CONTROL,
      customCSS: {},
      position: 'right',
      hidden: false,
    },
    {
      control: new controls.CustomControl(MapControlType.GEOFENCE_CONTROL),
      width: 36,
      controlType: MapControlType.GEOFENCE_CONTROL,
      customCSS: {},
      position: 'right',
      hidden: false,
    },
    {
      control: new controls.CustomControl(MapControlType.MEASURE_CONTROL),
      width: 36,
      controlType: MapControlType.MEASURE_CONTROL,
      customCSS: {},
      position: 'right',
      hidden: false,
    },
    {
      control: new controls.CustomControl(MapControlType.MAP_CONTROL),
      width: 68,
      controlType: MapControlType.MAP_CONTROL,
      customCSS: {},
      position: 'right',
      hidden: false,
    },
    {
      control: new controls.CustomControl(MapControlType.LOCATIONS_CONTROL),
      width: 68,
      controlType: MapControlType.LOCATIONS_CONTROL,
      customCSS: {},
      position: 'right',
      hidden: false,
    },
    {
      control: new controls.CustomControl(
        MapControlType.MONITORING_HISTORY_EVENTS,
      ),
      width: 68,
      controlType: MapControlType.MONITORING_HISTORY_EVENTS,
      customCSS: {},
      position: 'right',
      hidden: false,
    },
    {
      control: new controls.CustomControl(MapControlType.CUSTOM_SHOW_SIDEBAR),
      width: 36,
      controlType: MapControlType.CUSTOM_SHOW_SIDEBAR,
      customCSS: {
        display: hideSidebar ? 'block' : 'none',
      },
      position: 'left',
      hidden: !hideSidebar,
    },
    {
      control: new controls.CustomControl(
        MapControlType.CUSTOM_DATE_RANGE_PICKER,
      ),
      width: 250,
      controlType: MapControlType.CUSTOM_DATE_RANGE_PICKER,
      customCSS: {
        display: inHistoryMode ? 'block' : 'none',
      },
      position: 'left',
      hidden: false,
    },
  ];
  return controlList;
}

export const getLastDataReceivedOption = (lastDataDate?: number | null) => {
  return {
    label: t('monitoring.lastDataReceived'),
    value: lastDataDate
      ? format(new Date(lastDataDate), 'HH:mm:ss')
      : t('monitoring.N/A'),
  };
};

export function dateRangeDescription(startDate: Date, endDate: Date | null) {
  const today = new Date();

  if (!endDate) {
    return 'custom';
  }

  const checkDateRange = (date: Date) => {
    if (isToday(date)) return 'today';
    if (isYesterday(date)) return 'yesterday';

    const lastWeekStart = startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
    const lastWeekEnd = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
    if (isWithinInterval(date, { start: lastWeekStart, end: lastWeekEnd }))
      return 'lastWeek';

    const lastMonthStart = startOfMonth(subMonths(today, 1));
    const lastMonthEnd = endOfMonth(subMonths(today, 1));
    if (isWithinInterval(date, { start: lastMonthStart, end: lastMonthEnd }))
      return 'lastMonth';

    return 'custom';
  };

  const startRangeDescription = checkDateRange(startDate);
  const endRangeDescription = checkDateRange(endDate);

  if (startRangeDescription === endRangeDescription) {
    return startRangeDescription;
  }
  return 'custom';
}

export const getMonitoringSidebarCollapseState = () => {
  const stateFromStorage = localStorage.getItem(
    MONITORING_SIDEBAR_COLLAPSE_STATE,
  );
  if (stateFromStorage) {
    return JSON.parse(stateFromStorage);
  }
  return true;
};
export const getMonitoringTripDate = (
  startDate: Date,
  endDate: Date | null,
  appendDuration = true,
) => {
  if (Number.isNaN(startDate.getTime())) {
    throw new Error('Invalid startDate');
  }
  if (endDate && Number.isNaN(endDate.getTime())) {
    throw new Error('Invalid endDate');
  }
  const dateFormat = 'EEE dd MMM';
  const timeFormat = 'HH:mm:ss';

  const formattedStartDate = format(startDate, dateFormat);
  const formattedStartTime = format(startDate, timeFormat);

  const now = new Date();
  const actualEndDate = endDate || now;
  const formattedEndDate = format(actualEndDate, dateFormat);
  const formattedEndTime = endDate ? format(endDate, timeFormat) : 'Current';

  const durationInMs = Math.abs(actualEndDate.getTime() - startDate.getTime());
  const totalDays = Math.floor(durationInMs / (1000 * 60 * 60 * 24));
  const remainingHours = Math.floor(
    (durationInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const remainingMinutes = Math.floor(
    (durationInMs % (1000 * 60 * 60)) / (1000 * 60),
  );

  let result;
  if (formattedStartDate === formattedEndDate || !endDate) {
    result = `${formattedStartDate} ${formattedStartTime} - ${formattedEndTime}`;
    if (appendDuration) {
      if (totalDays >= 1) {
        result += `(${totalDays}d ${remainingHours}h ${remainingMinutes} min)`;
      } else {
        result += ` (${remainingHours}h ${remainingMinutes} min)`;
      }
    }
  } else {
    result = `${formattedStartDate} ${formattedStartTime} - ${formattedEndDate} ${formattedEndTime}`;
    if (appendDuration) {
      if (totalDays >= 1) {
        result += `(${totalDays}d ${remainingHours}h ${remainingMinutes} min)`;
      } else {
        result += ` (${remainingHours}h ${remainingMinutes} min)`;
      }
    }
  }

  return result;
};
export function getInternetAntennaTitle(
  signal?: number | null,
): SignalConnectionOptions {
  const fieldValue = {
    signalQuality: '',
    ratio: 0,
  };
  if (!signal) {
    fieldValue.signalQuality = AntennaSignalQuality.NO_SIGNAL;
    fieldValue.ratio = 0;
    return fieldValue;
  }
  fieldValue.ratio = signal;
  switch (!!signal) {
    case signal === 1:
      fieldValue.signalQuality = AntennaSignalQuality.VERY_WEAK;
      break;
    case signal === 2 || signal === 3:
      fieldValue.signalQuality = AntennaSignalQuality.WEAK;
      break;
    case signal === 4:
      fieldValue.signalQuality = AntennaSignalQuality.STRONG;
      break;
    default:
      fieldValue.signalQuality = AntennaSignalQuality.VERY_STRONG;
      break;
  }
  return fieldValue;
}
export function getGsmAntennaTitle(
  signal?: number | null,
): SignalConnectionOptions {
  const fieldValue = {
    signalQuality: '',
    ratio: 0,
  };
  if (!signal) {
    fieldValue.signalQuality = AntennaSignalQuality.NO_SIGNAL;
    return fieldValue;
  }
  fieldValue.ratio = signal;
  switch (!!signal) {
    case signal > 0 && signal < 6:
      fieldValue.signalQuality = AntennaSignalQuality.VERY_WEAK;
      break;
    case signal <= 11:
      fieldValue.signalQuality = AntennaSignalQuality.WEAK;
      break;
    case signal < 16:
      fieldValue.signalQuality = AntennaSignalQuality.STRONG;
      break;
    default:
      fieldValue.signalQuality = AntennaSignalQuality.VERY_STRONG;
      break;
  }
  return fieldValue;
}

type VehicleInfoData = Partial<MonitoringFlatTransportDTO> & {
  isDeviceActive: boolean;
  handleCopyClick: () => void;
  isActive: boolean;
  showAll: boolean;
  address: string | null;
};
export function InfoGenerator(vehicleInfo: VehicleInfoData): DetailType[] {
  const internetAntenna = getInternetAntennaTitle(vehicleInfo.gsmSignal);
  const gpsAntenna = getGsmAntennaTitle(vehicleInfo.satellites);
  const {
    status,
    satellitesQuality,
    address,
    gsmSignalQuality,
    handleCopyClick,
  } = vehicleInfo;
  const basicInfo: DetailType[] = [
    {
      id: 0,
      Icon: <DeviceStatusIcon />,
      label: t('monitoring.deviceStatus'),
      renderValue: () => (
        <Box sx={style.status}>
          <Box sx={style.statusDot(vehicleInfo.isDeviceActive)} />
          <Typography variant='body1' sx={style.listItemSecondaryText}>
            {t(`monitoring.${vehicleInfo.isDeviceActive ? '' : 'in'}active`)}
          </Typography>
        </Box>
      ),
    },
    {
      id: 1,
      Icon: <LastActiveIcon />,
      label: t('monitoring.lastActive'),

      renderValue: () => (
        <Typography variant='body1' sx={style.listItemSecondaryText}>
          {format(
            new Date(vehicleInfo.createdAt ? vehicleInfo.createdAt : 0),
            'dd/MM/yyyy HH:mm',
          )}
        </Typography>
      ),
    },
    {
      id: 2,
      Icon: <MovementStatusIcon width={18} height={18} status={status} />,
      label: t('monitoring.movementStatus'),
      renderValue: () => (
        <Box sx={style.status}>
          <Box sx={style.statusDot(vehicleInfo.isActive)} />
          <Typography variant='body1' sx={style.listItemSecondaryText}>
            {t(`monitoring.movementStatusType.${vehicleInfo.status}`)}
          </Typography>
        </Box>
      ),
    },
    {
      id: 3,
      Icon: <CurrentSpeedIcon />,
      label: t('monitoring.currentSpeed'),
      renderValue: () => (
        <Typography variant='body1' sx={style.listItemSecondaryText}>
          {vehicleInfo.speed || 0} km/h
        </Typography>
      ),
    },
    {
      id: 4,
      Icon: <NearMe color='action' sx={style.smallIcon} />,
      label: t('monitoring.coordinates'),
      renderValue: () => (
        <Box sx={style.addressInfo}>
          <Box sx={style.copy}>
            <ContentCopy
              color='action'
              onClick={handleCopyClick}
              sx={style.smallIcon}
            />
          </Box>
          <Typography
            variant='body1'
            sx={{ ...style.listItemSecondaryText, maxWidth: 100 }}
          >
            {formatLatLng(
              vehicleInfo.latitude as number,
              vehicleInfo.longitude as number,
            )}
          </Typography>
        </Box>
      ),
    },
    {
      id: 5,
      Icon: <LocationIcon />,
      label: t('monitoring.currentAddress'),
      renderValue: () => (
        <Typography variant='body1' sx={style.listItemSecondaryText}>
          {address || t('monitoring.N/A')}
        </Typography>
      ),
    },
  ];
  const additionalInfo: DetailType[] = [
    {
      id: 6,
      Icon: <VoltageIcon />,
      label: t('monitoring.voltage'),
      renderValue: () => (
        <Typography variant='body1' sx={style.listItemSecondaryText}>
          {vehicleInfo.externalVoltage
            ? `${(vehicleInfo.externalVoltage / 1000).toFixed(1)}V`
            : t('N/A')}
        </Typography>
      ),
    },
    {
      id: 7,
      Icon: (
        <AntennaSignalIcon
          quality={satellitesQuality as AntennaSignalQuality}
          width={18}
          height={18}
        />
      ),
      label: t('monitoring.gpsSignal'),
      renderValue: () => (
        <>
          <Typography variant='body1' sx={style.listItemSecondaryText}>
            {t(`monitoring.connectivityState.${gpsAntenna.signalQuality}`)}
          </Typography>
          {gpsAntenna.ratio ? (
            <Typography color='grey' variant='caption'>
              ({gpsAntenna.ratio}/33)
            </Typography>
          ) : null}
        </>
      ),
    },
    {
      id: 8,
      Icon: <ReliefIcon />,
      label: t('monitoring.relief'),
      renderValue: () => (
        <Typography variant='body1' sx={style.listItemSecondaryText}>
          {vehicleInfo.altitude}m
        </Typography>
      ),
    },
    {
      id: 9,
      Icon: (
        <AntennaSignalIcon
          quality={gsmSignalQuality as AntennaSignalQuality}
          width={18}
          height={18}
        />
      ),
      label: t('monitoring.connectivity'),
      renderValue: () => (
        <>
          <Typography variant='body1' sx={style.listItemSecondaryText}>
            {t(`monitoring.connectivityState.${internetAntenna.signalQuality}`)}
          </Typography>
          {internetAntenna.ratio ? (
            <Typography variant='body2' color='grey'>
              ({internetAntenna.ratio - 1}/4)
            </Typography>
          ) : null}
        </>
      ),
    },
  ];
  if (vehicleInfo.showAll) {
    return [...basicInfo, ...additionalInfo];
  }
  return basicInfo;
}
