import { Theme } from '@mui/material';

export const style = {
  listItem: (theme: Theme) => ({
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    height: 24,
    padding: theme.spacing(1.5, 2),
    '&:nth-of-type(odd)': {
      background: 'rgb(231, 231, 231)',
    },
    '&:nth-of-type(even)': {
      background: '#fff',
    },
  }),
  listItemPrimaryText: (theme: Theme) => ({
    color: theme.palette.text.secondary,
    fontWeight: 400,
  }),
};
