import { useEffect, useRef } from 'react';
import { Coordinate } from 'ol/coordinate';
import {
  getMonitoringTrackInfoSelector,
  getMonitoringVehiclesWithPositioningSelector,
} from '../selectors';
import { useAppSelector } from '../../layout/store';
import { MonitoringFlatTransportDTO } from '../types';
import { SFMap } from '../../map/services';

const useFollowTransports = () => {
  const trackInfo = useAppSelector(getMonitoringTrackInfoSelector);

  const vehicles = useAppSelector(getMonitoringVehiclesWithPositioningSelector);

  const vehiclesRef = useRef<Partial<MonitoringFlatTransportDTO>[]>(vehicles);

  vehiclesRef.current = vehicles;

  const { allSelected, transportId, teamId } = trackInfo;
  useEffect(() => {
    let coordinates: Coordinate[] = [];
    if (allSelected) {
      coordinates = vehiclesRef.current.map((vehicle) => [
        vehicle.longitude as number,
        vehicle.latitude as number,
      ]);
    } else if (transportId) {
      const vehicle = vehiclesRef.current.find(
        (transport) => transport.id === transportId,
      );
      if (vehicle) {
        coordinates = [
          [vehicle.longitude as number, vehicle.latitude as number],
        ];
      }
    } else if (teamId) {
      const teamVehicles = vehiclesRef.current.filter(
        (vehicle) => vehicle.team?.id === teamId,
      );
      coordinates = teamVehicles.map((vehicle) => [
        vehicle.longitude as number,
        vehicle.latitude as number,
      ]);
    }
    SFMap.fitToCoordinates(coordinates);
  }, [allSelected, transportId, teamId]);
};

export default useFollowTransports;
