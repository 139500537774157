import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore, History } from '@mui/icons-material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DeviceStatusCar from '../../../../assets/monitoring-device-details-icons/monitoring-device-status-by-car.svg?component';
import { style } from './style';
import {
  MonitoringFlatTransportDTO,
  MonitoringMovementStatus,
} from '../../types';
import { formatLatLng, NotificationApi, ROUTES } from '../../../global';
import AvatarImage from '../../../shared/components/AvatarImage';
import { toggleSearchParam } from '../../../shared/utils';
import { InfoGenerator } from '../../utils';
import ListRenderer, {
  DetailType,
} from '../../../shared/components/ListRenderer';
import { GeoService } from '../../../services';

type Props = {
  vehicleInfo: Partial<MonitoringFlatTransportDTO>;
};

function MonitoringDeviceDetails({ vehicleInfo }: Props) {
  const [showAll, setShowAll] = useState(false);
  const showMoreRef = useRef<HTMLButtonElement | null>(null);
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const [address, setAddress] = useState<null | string>(null);
  const getCurrentLocation = async () => {
    if (!vehicleInfo.latitude || !vehicleInfo.longitude) return;
    const { data, ok } = await GeoService.reverseSearch([
      vehicleInfo.longitude,
      vehicleInfo.latitude,
    ]);
    if (ok && data) {
      setAddress(data.address);
    }
  };
  useEffect(() => {
    getCurrentLocation();
  }, []);

  const isActive = vehicleInfo.status === MonitoringMovementStatus.MOVEMENT;

  const isDeviceActive = vehicleInfo.device?.status === 1;

  const toggleShowAll = useCallback(() => {
    setShowAll(!showAll);
  }, [showAll]);

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(
      formatLatLng(
        vehicleInfo.latitude as number,
        vehicleInfo.longitude as number,
      ),
    );
    NotificationApi.showSuccess(t('copiedToClipboard'));
  }, [vehicleInfo, t]);
  const vehicleShowingData: DetailType[] = InfoGenerator({
    ...vehicleInfo,
    showAll,
    isDeviceActive,
    isActive,
    handleCopyClick,
    address,
  });

  useEffect(() => {
    // We are attaching click event to copyRef because
    // currently we cannot attach it using onClick
    // this is a content rendered in a portal
    // and copy icon is rendered in a overlay
    if (showMoreRef.current) {
      showMoreRef.current.addEventListener('click', toggleShowAll);
    }
  }, [toggleShowAll]);

  const showHistory = () => {
    setSearchParams((params) =>
      toggleSearchParam(
        params,
        ROUTES.monitoring.searchParams.transportID,
        vehicleInfo.id,
      ),
    );
  };

  return (
    <>
      <Box sx={style.cardHeader}>
        <AvatarImage
          size='L'
          altName='Image'
          imageId={vehicleInfo.imageId}
          avatarSX={{ position: 'relative' }}
        />
        <Box sx={style.deviceStatus(vehicleInfo.device?.status)}>
          <DeviceStatusCar />
        </Box>

        <Box sx={style.title}>
          <Box sx={style.header}>
            <Typography sx={style.licensePlate} variant='subtitle1'>
              {vehicleInfo.licensePlate || t('N/A')}
            </Typography>
            <Button
              startIcon={<History />}
              sx={style.historyButton}
              onClick={showHistory}
              size='small'
            >
              {t('showHistory')}
            </Button>
          </Box>
          <Box sx={style.transportField}>
            <Typography sx={style.transportName} variant='body1'>
              {vehicleInfo.name || t('N/A')}
            </Typography>
            <Box sx={style.dot} component='span' />
            <Typography sx={style.listItemPrimaryText} variant='body1'>
              {vehicleInfo.courier
                ? `${vehicleInfo.courier.firstName} ${vehicleInfo.courier.lastName}`
                : t('N/A')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <ListRenderer data={vehicleShowingData} />
      </Box>

      <Button
        endIcon={showAll ? <ExpandLess /> : <ExpandMore />}
        sx={style.showMore}
        ref={showMoreRef}
      >
        {t(`see${showAll ? 'Less' : 'More'}`)}
      </Button>
    </>
  );
}

export default MonitoringDeviceDetails;
