export const style = {
  paper: (left: string) => ({
    left,
    bottom: {
      xs: 0,
      sm: 8,
    },
    background: 'transparent',
    transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) !important',
    border: 0,
  }),
};
