import GSMBadSignal from '../../../assets/gsm-signal-icons/GSMBadSignal.svg?component';
import GSMNotSignal from '../../../assets/gsm-signal-icons/GSMNotSignal.svg?component';
import GSMLowSignal from '../../../assets/gsm-signal-icons/GSMLowSignal.svg?component';
import GSMNormalSignal from '../../../assets/gsm-signal-icons/GSMNormalSignal.svg?component';
import GSMGoodSignal from '../../../assets/gsm-signal-icons/GSMGoodSignal.svg?component';
import { MonitoringDateFilter, MonitoringReportOption } from '../types';
import NotSignalAntenna from '../../../assets/internet-antenna-status/NotSignalAntenna.svg?component';
import BadSignalAntenna from '../../../assets/internet-antenna-status/BadSignalAntenna.svg?component';
import LowSignalAntenna from '../../../assets/internet-antenna-status/LowSignalAntenna.svg?component';
import NormalSignalAntenna from '../../../assets/internet-antenna-status/NormalSignalAntenna.svg?component';
import GoodSignalAntenna from '../../../assets/internet-antenna-status/GoodSignalAntenna.svg?component';

export const MONITORING_SIDEBAR_COLLAPSE_STATE =
  'MONITORING_SIDEBAR_COLLAPSE_STATE';

export const MONITORING_SPEED_LIST = [1, 2, 4, 8, 16, 32, 64];

export const MONITORING_DRAWER_COLLAPSED_WIDTH = 0;
export const MONITORING_DRAWER_WIDTH = 392;

export const GEOFENCE_DRAWER_COLLAPSED_WIDTH = 0;
export const GEOFENCE_DRAWER_WIDTH = 392;

export const MONITORING_GENERAL_SECTION_INITIAL_HEIGHT = 310;
export const MONITORING_TRIPS_SECTION_INITIAL_HEIGHT = 369;
export const MONITORING_NOTIFICATIONS_SECTION_INITIAL_HEIGHT = 290;

export const MONITORING_TRIPS_SECTION_PLAYER_ENABLED_HEIGHT = 240;

export const MONITORING_WRAPPER_MAX_WIDTH = 904;
export const MONITORING_RESIZABLE_MIN_HEIGHT = 164;
export const MONITORING_RESIZABLE_MAX_HEIGHT = '85vh';

export enum AntennaSignalQuality {
  NO_SIGNAL = 'NO_SIGNAL',
  VERY_WEAK = 'VERY_WEAK',
  WEAK = 'WEAK',
  STRONG = 'STRONG',
  VERY_STRONG = 'VERY_STRONG',
}
export const GSMSignalIcons = {
  [AntennaSignalQuality.NO_SIGNAL]: GSMNotSignal,
  [AntennaSignalQuality.VERY_WEAK]: GSMBadSignal,
  [AntennaSignalQuality.WEAK]: GSMLowSignal,
  [AntennaSignalQuality.STRONG]: GSMNormalSignal,
  [AntennaSignalQuality.VERY_STRONG]: GSMGoodSignal,
};

export const AntennaSignalIcons = {
  [AntennaSignalQuality.NO_SIGNAL]: NotSignalAntenna,
  [AntennaSignalQuality.VERY_WEAK]: BadSignalAntenna,
  [AntennaSignalQuality.WEAK]: LowSignalAntenna,
  [AntennaSignalQuality.STRONG]: NormalSignalAntenna,
  [AntennaSignalQuality.VERY_STRONG]: GoodSignalAntenna,
};
export const ReportOptionsItems: { id: number; value: string }[] = [
  { id: 0, value: MonitoringReportOption.SPEED },
  { id: 1, value: MonitoringReportOption.TEMPERATURE },
  { id: 2, value: MonitoringReportOption.GPS_SIGNAL },
  { id: 3, value: MonitoringReportOption.GSM_SIGNAL },
  { id: 4, value: MonitoringReportOption.RELIEF },
  { id: 5, value: MonitoringReportOption.VOLTAGE },
];

export const MonitoringDateFilters: MonitoringDateFilter[] = [
  'custom',
  'today',
  'yesterday',
  'lastWeek',
  'lastMonth',
];
