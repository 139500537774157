import MonitoringSidebarControl from '../MonitoringSidebarControl';
import ControlsWrapper from '../../../shared/components/ControlsWrapper';
import { MONITORING_DRAWER_WIDTH } from '../../utils/constants';

type MonitoringControlsProps = {
  showSidebar: () => void;
  sidebarHidden: boolean;
};

function MonitoringControls(props: MonitoringControlsProps) {
  const { showSidebar, sidebarHidden } = props;

  const controlsLeft = sidebarHidden ? 0 : MONITORING_DRAWER_WIDTH;

  return (
    <ControlsWrapper sx={{ top: 0, left: controlsLeft }}>
      <MonitoringSidebarControl
        showSidebar={showSidebar}
        sidebarHidden={sidebarHidden}
      />
    </ControlsWrapper>
  );
}

export default MonitoringControls;
