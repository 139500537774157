import { AntennaSignalQuality } from './constants';

function getGPSSignalQuality(satellites?: number) {
  if (!satellites) return AntennaSignalQuality.NO_SIGNAL;
  if (satellites > 1 && satellites < 6) {
    return AntennaSignalQuality.VERY_WEAK;
  }
  if (satellites < 11) {
    return AntennaSignalQuality.WEAK;
  }
  if (satellites < 16) {
    return AntennaSignalQuality.STRONG;
  }
  return AntennaSignalQuality.VERY_STRONG;
}

export { getGPSSignalQuality };
