import { Avatar, Box, SvgIconProps, SxProps, Theme } from '@mui/material';
import {
  memo,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BrokenImage } from '@mui/icons-material';
import { style } from './style';
import MediaService from '../../../services/media.service';
import { mergeSX } from '../../../global';

type Props = {
  altName: string;
  imageId: string | null | undefined;
  onClick?: MouseEventHandler<HTMLElement>;
  size?: 'L' | 'M' | 'XM' | 'XL' | 'FULL';
  avatarSX?: SxProps<Theme>;
  brokenImageProps?: SvgIconProps;
  children?: ReactNode;
};
function AvatarImage({
  altName,
  imageId,
  onClick,
  size = 'M',
  avatarSX,
  brokenImageProps = {},
  children,
}: Props) {
  const isCursorPointer = !!onClick;
  const [isImageFound, setIsImageFound] = useState(true);

  const imageSize = useMemo(() => {
    const resultingSize = {
      width: '0',
      height: '0',
      fontSize: 'inherit',
    };
    if (size === 'L') {
      resultingSize.width = '40px';
      resultingSize.height = '40px';
    } else if (size === 'M') {
      resultingSize.width = '24px';
      resultingSize.height = '24px';
    } else if (size === 'XM') {
      resultingSize.width = '28px';
      resultingSize.height = '28px';
    } else if (size === 'XL') {
      resultingSize.width = '32px';
      resultingSize.height = '32px';
    } else if (size === 'FULL') {
      resultingSize.width = '100%';
      resultingSize.height = '100%';
      resultingSize.fontSize = '52px';
    }
    return resultingSize;
  }, [size]);

  const handleError = () => {
    setIsImageFound(false);
  };

  useEffect(() => {
    setIsImageFound(true);
  }, [imageId]);

  if (imageId && isImageFound) {
    return (
      <Box
        component='img'
        src={MediaService.getUrlOfImage(imageId)}
        title={altName}
        width={imageSize.width}
        height={imageSize.height}
        sx={[style.image, isCursorPointer && style.pointer]}
        key={imageId}
        onClick={onClick}
        onError={handleError}
      />
    );
  }

  const avatarSXProps = mergeSX(
    isCursorPointer ? style.pointer : undefined,
    imageSize,
    avatarSX,
  );

  if (!isImageFound) {
    return (
      <Avatar onClick={onClick} sx={avatarSXProps} title='Broken Image'>
        <BrokenImage
          color='inherit'
          fontSize={size === 'FULL' ? 'inherit' : 'small'}
          {...brokenImageProps}
        />
        {children}
      </Avatar>
    );
  }

  return (
    <Avatar onClick={onClick} sx={avatarSXProps}>
      {children}
    </Avatar>
  );
}
export default memo(AvatarImage);
