import OLMap from 'ol/Map';
import { Feature, MapBrowserEvent, Overlay, View } from 'ol';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';
import BaseLayer from 'ol/layer/Base';
import { createXYZ } from 'ol/tilegrid';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import VectorSource from 'ol/source/Vector';
import { Layer, Vector } from 'ol/layer';
import BaseEvent from 'ol/events/Event';
import { Coordinate } from 'ol/coordinate';
import { Control } from 'ol/control';
import VectorLayer from 'ol/layer/Vector';
import { Stroke, Style } from 'ol/style';
import { LineString } from 'ol/geom';
import { boundingExtent } from 'ol/extent';
import { unByKey } from 'ol/Observable';
import { FeatureLike } from 'ol/Feature';
import { FitOptions } from 'ol/View';
import {
  MapType,
  MarkerConfig,
  SFControlType,
  SFMarkerInstance,
} from '../types';
import { YEREVAN_CENTER_LOCATION } from '../../global/utils/constants';
import SFGeofence from './geofence.service';
import SFMarker from './marker.service';
import SFMeasure from './measure.service';
import SFControl from './control.service';
import SFRoute from './route.service';
import { MessageDTO } from '../../shared/types';
import TripRenderer from '../utils/trip-renderer';

proj4.defs([
  [
    'EPSG:3395',
    '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs +type=crs',
  ],
  [
    'EPSG:3875',
    '+title=Web Mercator +proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs',
  ],
]);

register(proj4);
class SFMap {
  map: OLMap;

  private overlayLayers: Set<BaseLayer>;

  private baseLayer: BaseLayer | null;

  private geofence: SFGeofence;

  private markerManager: SFMarker;

  private measure: SFMeasure;

  private controlManager: SFControl;

  private routeService: SFRoute;

  private mapLayers: Record<string, Layer | null>;

  tripRendererInstance: TripRenderer | null;

  constructor() {
    this.map = new OLMap({
      view: new View({
        center: fromLonLat([
          YEREVAN_CENTER_LOCATION.lng,
          YEREVAN_CENTER_LOCATION.lat,
        ]),
        zoom: 13,
      }),
      controls: [],
    });
    this.baseLayer = null;
    this.overlayLayers = new Set();
    this.geofence = new SFGeofence(this);
    this.markerManager = new SFMarker(this);
    this.measure = new SFMeasure(this);
    this.controlManager = new SFControl(this);
    this.routeService = new SFRoute(this);
    this.tripRendererInstance = null;
    this.mapLayers = {
      [MapType.OSM_MAP]: null,
      [MapType.GOOGLE_TERRAIN]: null,
      [MapType.GOOGLE_SATELLITE]: null,
      [MapType.GOOGLE_TRAFFIC]: null,
      [MapType.YANDEX_TERRAIN]: null,
    };
    this.initMapLayers();
  }

  initMapLayers() {
    this.mapLayers[MapType.GOOGLE_TERRAIN] = new TileLayer({
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
      }),
      visible: false,
    });
    this.mapLayers[MapType.GOOGLE_SATELLITE] = new TileLayer({
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
      }),
      visible: false,
    });
    this.mapLayers[MapType.GOOGLE_TRAFFIC] = new TileLayer({
      source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}',
      }),
      visible: false,
    });
    const yandexTileGrid = createXYZ({
      tileSize: 256,
      extent: [-20037500.72, -20037500.72, 20037500.72, 20037500.72],
    });
    this.mapLayers[MapType.YANDEX_TERRAIN] = new TileLayer({
      source: new XYZ({
        url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}',
        projection: 'EPSG:3395',
        tileGrid: yandexTileGrid,
      }),
      visible: false,
    });
    this.mapLayers[MapType.OSM_MAP] = new TileLayer({
      source: new OSM({ attributions: '' }),
      visible: false,
    });
    Object.values(this.mapLayers).forEach((layer) => {
      this.map.addLayer(layer as Layer);
    });
  }

  initMap(element: HTMLElement) {
    this.map.setTarget(element);
    return () => {
      this.map.setTarget();
    };
  }

  attachControls(controls: SFControlType[]) {
    return this.controlManager.attachControls(controls);
  }

  attachControl(control: Control) {
    return this.controlManager.attachControl(control);
  }

  setLayer(mapType: MapType) {
    Object.entries(this.mapLayers).forEach(([key, value]) => {
      value!.setVisible(key === mapType);
    });
  }

  addOverlayLayer(layer: BaseLayer) {
    if (!this.overlayLayers.has(layer)) {
      this.overlayLayers.add(layer);
      this.map.addLayer(layer);
    }
  }

  getCenter() {
    return this.map.getView().getCenter() as [number, number];
  }

  getEditableCircle() {
    return this.geofence.circle;
  }

  createEditableCircle(onCenterChange: (center: number[]) => unknown) {
    return this.geofence.createCircle(this.getCenter(), 1000, onCenterChange);
  }

  createMarker(config: MarkerConfig): SFMarkerInstance {
    return this.markerManager.createMarker(config);
  }

  drawEditablePolygon(
    onShapeChange: (event: BaseEvent | Event) => unknown,
    paths?: Coordinate[][],
  ) {
    return this.geofence.createEditablePolygon(onShapeChange, paths);
  }

  getInternalMap() {
    return this;
  }

  getMap() {
    return this.map;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeOverlayLayer(layer: Vector<VectorSource<Feature<any>>>) {
    this.map.removeLayer(layer);
    this.overlayLayers.delete(layer);
  }

  setCenter(position: Coordinate) {
    this.map.getView().setCenter(fromLonLat(position));
  }

  setCenterSmooth(position: Coordinate) {
    this.map.getView().animate({ center: fromLonLat(position), duration: 250 });
  }

  fitToCoordinates(coordinates: Coordinate[]) {
    if (!coordinates.length) {
      return;
    }

    coordinates = coordinates.map((coordinate) => fromLonLat(coordinate));

    const extent = boundingExtent(coordinates);
    this.map.getView().fit(extent, {
      maxZoom: 17,
      size: this.map.getSize(),
      duration: 500,
    });
  }

  setZoomLevel(zoom: number) {
    this.map.getView().setZoom(zoom);
  }

  resetZoomLevel() {
    this.map.getView().setZoom(13);
  }

  enableMeasureTool() {
    return this.measure.enableMeasureTool();
  }

  subscribeToControls(fn: () => void) {
    return this.controlManager.subscribeToChange(fn);
  }

  zoomIn() {
    const view = this.map.getView();
    const currentZoomLevel = view.getZoom() || 13;
    view.animate({
      zoom: currentZoomLevel + 1,
      duration: 250,
    });
  }

  zoomOut() {
    const view = this.map.getView();
    const currentZoomLevel = view.getZoom() || 13;
    view.animate({
      zoom: currentZoomLevel - 1,
      duration: 250,
    });
  }

  createOverlay(element: HTMLElement) {
    const overlay = new Overlay({
      element,
      autoPan: true,
    });

    const cleanup = () => {
      this.map.removeOverlay(overlay);
    };

    this.map.addOverlay(overlay);

    return { cleanup, overlay };
  }

  public makeCursorPointerOnMove() {
    const handlePointerMove = (evt: MapBrowserEvent<UIEvent>) => {
      if (!evt.map || !evt.map.getView()) {
        return;
      }

      // If the user is dragging the map, skip changing the cursor
      if (evt.dragging) {
        return;
      }

      // evt.pixel gives us the pixel location of the pointer
      const { pixel } = evt;

      // TODO rewrite
      const hit = evt.map.hasFeatureAtPixel(pixel, {
        layerFilter: (layer) => {
          return layer instanceof VectorLayer && layer.get('id');
        },
      });

      // Get the map's target HTML element
      const target = evt.map.getTargetElement();
      if (target) {
        target.style.cursor = hit ? 'pointer' : '';
      }
    };

    // Listen for pointermove and store the returned key so we can unbind later
    const pointerMoveKey = this.map.on('pointermove', handlePointerMove);

    // Return a cleanup function to remove the event listener
    return {
      cleanup: () => {
        unByKey(pointerMoveKey);
      },
    };
  }

  drawLineString(messages: MessageDTO[], fitOptions: FitOptions) {
    if (messages.length < 2) return { cleanup: () => {} };
    this.centerMapToCoordinates(
      messages.map((m) => [m.longitude, m.latitude]),
      fitOptions,
    );

    const coords = messages.map((m) => fromLonLat([m.longitude, m.latitude]));
    const geometry = new LineString(coords);
    geometry.simplify(0);

    const feature = new Feature({ geometry });

    const speeds = messages.map((v) => v.speed);
    feature.set('speeds', speeds);

    const segmentStyleFunction = (featureLike: FeatureLike) => {
      const styles: Style[] = [];
      const geom = featureLike.getGeometry() as LineString;
      const lineCoords = geom.getCoordinates();
      const featureSpeeds: number[] = featureLike.get('speeds') || [];

      styles.push(
        new Style({
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.6)',
            width: 8,
          }),
          // use the original geometry
          geometry: geom,
        }),
      );

      for (let i = 0; i < lineCoords.length - 1; i += 1) {
        const segmentGeom = new LineString([lineCoords[i], lineCoords[i + 1]]);

        const segmentSpeed = Math.max(featureSpeeds[i], featureSpeeds[i + 1]);
        const segmentColor = this.getColorFromSpeed(segmentSpeed, 0, 120);

        styles.push(
          new Style({
            stroke: new Stroke({
              color: segmentColor,
              width: 4,
            }),
            geometry: segmentGeom,
          }),
        );
      }

      return styles;
    };

    feature.setStyle(segmentStyleFunction);

    const vectorLayer = new VectorLayer({
      source: new VectorSource({ features: [feature] }),
    });
    this.map.addLayer(vectorLayer);

    return {
      cleanup: () => {
        this.map.removeLayer(vectorLayer);
      },
    };
  }

  private getColorFromSpeed(speed: number, minSpeed: number, maxSpeed: number) {
    const safeSpeed = Math.max(minSpeed, Math.min(speed, maxSpeed));
    const ratio = (safeSpeed - minSpeed) / (maxSpeed - minSpeed);
    const hue = 120 * (1 - ratio); // 120 = green, 0 = red
    return `hsl(${hue}, 100%, 50%)`;
  }

  centerMapToCoordinates(coordinates: Coordinate[], options: FitOptions) {
    const transformedCoordinates = coordinates.map((coord) =>
      fromLonLat(coord),
    );
    const extent = boundingExtent(transformedCoordinates);

    this.map.getView().fit(extent, {
      padding: [50, 50, 50, 50],
      duration: 250,
      maxZoom: 18,
      ...options,
    });

    const cleanup = () => {
      this.setCenterSmooth([
        YEREVAN_CENTER_LOCATION.lng,
        YEREVAN_CENTER_LOCATION.lat,
      ]);
      this.map.getView().setZoom(13);
    };

    return { cleanup };
  }

  getRouteService() {
    return this.routeService;
  }

  getRotationAngle = (rotation: number) => {
    return (rotation * Math.PI) / 180;
  };

  setTripRendererInstance(tripRendererInstance: TripRenderer) {
    this.tripRendererInstance = tripRendererInstance;
  }

  getTripRendererInstance() {
    return this.tripRendererInstance;
  }
}

const SFMapView = new SFMap();

export type SFMapType = typeof SFMapView;

export { SFMapView as SFMap };
